<script>
import api from '@/command/api'
import moment from 'moment'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { Button, DatePicker, Modal, Switch } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
import { deleteAction, getAction, postAction, putAction } from '@/command/netTool'
import { getAllDates } from '@/utils/dateHandle'
export default {
  name: 'calendar',
  data() {
    return {
      ...api.command.getState(),
      daytime: moment(new Date()).format('YYYY-MM-DD'),
      batchSetData: [],
      dateAdds: [
        {
          date_g: '2022-09-14',
          week: '周三',
        },
      ],
      // 是否免费
      isFree: false,
      weekList: [
        {
          name: '星期一',
          value: '1',
        },
        {
          name: '星期二',
          value: '2',
        },
        {
          name: '星期三',
          value: '3',
        },
        {
          name: '星期四',
          value: '4',
        },
        {
          name: '星期五',
          value: '5',
        },
        {
          name: '星期六',
          value: '6',
        },
        {
          name: '星期日',
          value: '7',
        },
      ],
      dayList: [],
      productId: '',
      skuId: '',
      productType: '',
      thirdTicket: '',
      dateArr: [],
    }
  },
  watch: {
    daytime(nVal, oVal) {
      this.getInit()
    },
    isFree(nVal) {
      putAction('/goods/farmTicket/freeSwitch', {
        productId: this.productId,
        isFree: nVal ? '1' : '0',
      })
    },
  },
  mounted() {
    const { productId, productType, thirdTicket, skuId } = this.$route.query
    this.productId = productId
    this.productType = productType
    this.thirdTicket = thirdTicket
    this.skuId = skuId
    this.getInit()
    for (let i = 1; i <= moment(new Date()).daysInMonth(); i++) {
      this.dayList.push({
        name: i,
        value: `${i}`,
      })
    }
  },
  methods: {
    getWeek(dateArr) {
      if (!dateArr.length) return
      let arr = [...new Set(dateArr.map((e) => moment(e).day()))]
      this.weekList = this.weekList.map((e) => {
        if (e.value == '7') {
          return {
            ...e,
            disabled: !arr.includes(0),
          }
        } else {
          return {
            ...e,
            disabled: !arr.includes(Number(e.value)),
          }
        }
      })
    },
    getWeek2(dateArr) {
      if (!dateArr.length) return
      let arr = [...new Set(dateArr.map((e) => moment(e).format('D')))]
      this.dayList = this.dayList.map((e) => {
        return {
          ...e,
          disabled: !arr.includes(e.value),
        }
      })
    },
    getInit() {
      const { free } = this.$route.query
      if (free) {
        getAction('/api/goods/farmTicket/detail', { id: this.productId }).then((e) => {
          this.isFree = e.data.isFree == '0' ? false : true
        })
      }

      getAction(
        '/goods/farmTicketPriceConfig/queryCalendarList',
        {
          skuId: this.skuId,
          productId: this.productId,
          productType: this.productType,
          date: this.daytime,
        },
        '/api'
      ).then(({ data }) => {
        const { titleList, dataList } = data
        this.dateAdds = titleList.map((e) => {
          return {
            date_g: e.title,
            week: e.weekDesc,
          }
        })
        this.records = dataList
      })
    },
    getColumns() {
      let arr = [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
        },
        {
          dataIndex: 'productName',
          title: '名称',
          align: 'center',
        },
      ]
      this.dateAdds.forEach((e, indexId) => {
        arr.push({
          align: 'center',
          title: () => {
            return (
              <div>
                <div>{e.date_g}</div>
                <div>{e.week}</div>
              </div>
            )
          },
          customRender: (text, records, index) => {
            let is_show = this.thirdTicket == '1'
            const data = [
              {
                name: '原价',
                value: records['list'][indexId]['originalPrice'],
              },
              {
                name: '销售价',
                value: records['list'][indexId]['salePrice'],
              },
              {
                name: '结算价',
                value: records['list'][indexId]['jsPrice'],
              },
              {
                name: '库存',
                value: records['list'][indexId]['allStock'],
              },
            ]
            return (
              <div
                class={`product-order-item-copy ${is_show ? 'no' : 'yes'}`}
                onClick={() => !is_show && this.itemClick(e, records)}
              >
                {data.map((e) => {
                  return (
                    <div>
                      {e.name}:{e.value || 0}
                    </div>
                  )
                })}
              </div>
            )
          },
        })
      })
      return arr
    },
    itemClick(e, records) {
      if (this.isFree) {
        this.$message.error('免费模式下不能设置日历价')
        return
      }
      let { date_g } = e
      let { list, productName } = records
      let obj = list.find((f) => f.date === date_g) || {
        originalPrice: 0,
        salePrice: 0,
        jsPrice: 0,
        allStock: 0,
      }
      // let { originalPrice, salePrice, jsPrice, allStock } = obj
      // const state = originalPrice && salePrice && jsPrice && allStock
      // if (!state) {
      //   this.$message.error('请检查输入的价格')
      //   flag = true
      //   return
      // }
      apiTool.showModal({
        width: '600px',
        title: '日历价格设置',
        success: ({ data, setHidden }) => {
          let obj = Object.assign({}, data, {
            productId: records.productId,
            skuId: records.skuId,
            productType: this.productType,
          })
          let { originalPrice, salePrice, jsPrice, allStock } = data

          if (!allStock) {
            this.$message.error('库存须大于0')
            return
          }
          if (!(jsPrice <= salePrice)) {
            this.$message.error('结算价不能大于销售价')
            return
          }
          postAction('/goods/farmTicketPriceConfig/saveByDate', obj).then((res) => {
            if (res.code == 200) {
              this.getInit()
              this.$message.success('操作成功')
              setHidden()
            } else {
              this.$message.error('操作失败')
            }
          })
        },
        formData: {
          name: productName,
          date: date_g,
          ...obj,
        },
        form: [
          {
            name: '商品名',
            type: 'text',
            key: 'name',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
          },
          {
            name: '日期',
            type: 'text',
            key: 'date',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
          },
          {
            name: '原价',
            type: 'inputNumber',
            key: 'originalPrice',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
            rules: [{ required: true, type: 'number' }],
            props: {
              min: 0,
            },
          },
          {
            name: '销售价',
            type: 'inputNumber',
            key: 'salePrice',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
            rules: [{ required: true, type: 'number' }],
            props: {
              min: 0,
            },
          },
          {
            name: '结算价',
            type: 'inputNumber',
            key: 'jsPrice',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
            rules: [{ required: true, type: 'number' }],
            props: {
              min: 0,
            },
          },
          {
            name: '库存',
            type: 'inputNumber',
            key: 'allStock',
            disabled: this.thirdTicket == '1',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
            rules: [{ required: true, type: 'number' }],
            props: {
              min: 0,
              precision: 0,
            },
          },
        ],
      })
    },
    tenDaysAgo() {
      this.daytime = apiTool.recentTime(-10, 'yyyy-MM-dd', this.daytime)
    },
    tenDaysLaters() {
      this.daytime = apiTool.recentTime(10, 'yyyy-MM-dd', this.daytime)
    },
    onChange(time) {
      this.daytime = moment(time).format('YYYY-MM-DD')
    },
    showConfirm() {
      Modal.confirm({
        title: '提示',
        content: '此操作将删除该商品所有价格, 是否继续？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let skuId = this.$refs.tableExternal.$refs.table.selectedRows.map((e) => e.skuId)
          if (!skuId.length) {
            this.$message.error('至少勾选一个商品')
            return
          }
          deleteAction(
            '/goods/farmTicketPriceConfig/clearALL?productId=' +
              this.productId +
              '&productType=' +
              this.productType +
              '&skuId=' +
              skuId,
            {},
            '/api'
          ).then((res) => {
            if (res.code == 200) {
              this.getInit()
              this.$message.success('操作成功')

              this.$refs.tableExternal.$refs.table.selectedRows = []
              this.$refs.tableExternal.$refs.table.selectedRowKeys = []
            } else {
              this.$message.error('操作失败')
            }
          })
        },
        onCancel() {},
      })
    },
    getHeaderRender() {
      const { free } = this.$route.query
      return (
        <div class="header-button">
          <span>
            <Button type="primary" onClick={this.batchSet} disabled={this.thirdTicket == '1'}>
              批量设置
            </Button>
            <Button style={{ marginLeft: '12px' }} type="default" onClick={this.showConfirm}>
              清除报价
            </Button>
            <Button style={{ marginLeft: '12px' }} type="primary" onClick={this.onDistribution}>
              分销设置
            </Button>
            {free && (
              <span
                style={{
                  marginLeft: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span style={{ marginRight: '10px' }}>免费</span>
                <Switch v-model={this.isFree} />
              </span>
            )}
          </span>
          <span>
            <Button type="default" onClick={this.tenDaysAgo} disabled={this.isFree}>
              ＜ 前10天
            </Button>
            <DatePicker
              allowClear={false}
              onChange={this.onChange}
              style={{ width: '100%', marginLeft: '12px' }}
              value={this.daytime}
              disabled={this.isFree}
            ></DatePicker>
            <Button disabled={this.isFree} style={{ marginLeft: '12px' }} type="default" onClick={this.tenDaysLaters}>
              后10天 ＞
            </Button>
          </span>
        </div>
      )
    },
    getFxForm(formInfo) {
      let that = this
      return [
        {
          name: '加入分销商品',
          type: 'switchOpen',
          cols: 16,
          key: 'joinFx',
          showText: ['开', '关'],
        },
        {
          type: 'table',
          showFootButton: false,
          showRowSelect: false,
          showPagination: false,
          labelCol: { span: 0 },
          wrapperCol: { span: 24 },
          header:()=>"平台设置好佣金比例，赚取佣金",
          columns: [
            {
              dataIndex: 'name',
              align: 'left',
              width: '20%',
              title: '类型',
            },
            {
              dataIndex: 'value',
              align: 'left',
              width: '10%',
              title: '佣金比例',
              customRender: function (text, records) {
                const onInput = (data) => {
                  if (data.target.value) {
                    if (!/[0-9\.]/.test(data.target.value)) {
                      data.target.value = null
                      that.$message.warning('请输入数字！')
                      return false
                    } else if (data.target.value < 0) {
                      that.$message.warning('请输入正数！')
                      data.target.value = null
                      return false
                    } else {
                      records.value = data.target.value
                    }
                  } else {
                    records.value = ''
                  }
                }
                return (
                  <a-input
                    type="number"
                    placeholder="请输入原价"
                    addon-after="%"
                    onChange={onInput}
                    value={text}
                    min={0}
                  />
                )
              },
            },
          ],
          dataSource: formInfo.yjData,
        },
      ]
    },
    getForm(formInfo) {
      let that = this
      return [
        {
          name: '日期',
          type: 'rangePicker',
          keys: ['startDate', 'endDate'],
          ranges: {
            最近一周: [moment(), moment().add(1, 'weeks')],
            最近一个月: [moment(), moment().add(1, 'month')],
            最近三个月: [moment(), moment().add(3, 'month')],
          },
          labelCol: { span: 4 },
          wrapperCol: { span: 15 },
          onChange: (first) => {
            if (first.length) {
              let startTime = moment(first[0]).format('YYYY-MM-DD')
              let endTime = moment(first[1]).format('YYYY-MM-DD')
              this.dateArr = getAllDates(startTime, endTime)
              this.getWeek(this.dateArr)
              this.getWeek2(this.dateArr)
            } else {
              this.dateArr = []
            }
          },
        },
        {
          name: '日期方式',
          type: 'radioButton',
          key: 'dateType',
          labelCol: { span: 4 },
          wrapperCol: { span: 19 },
          typeData: [
            {
              name: '全部',
              value: '0',
            },
            {
              name: '指定星期',
              value: '1',
            },
            {
              name: '指定日期',
              value: '2',
            },
          ],
          onChange: () => {
            formInfo.dateValue = []
          },
        },
        ...[
          {
            name: '选择星期',
            type: 'checkBoxButton',
            key: 'dateValue',
            labelCol: { span: 4 },
            wrapperCol: { span: 19 },
            typeData: this.weekList,
            display: formInfo.dateType == '1',
          },
          {
            name: '选择日期',
            type: 'checkBoxButton',
            key: 'dateValue',
            labelCol: { span: 4 },
            wrapperCol: { span: 19 },
            typeData: this.dayList,
            display: formInfo.dateType == '2',
            styles: {
              width: '80px',
            },
          },
        ].filter((e) => e.display),
        {
          type: 'table',
          showFootButton: false,
          showRowSelect: false,
          showPagination: false,
          labelCol: { span: 0 },
          wrapperCol: { span: 24 },
          columns: [
            {
              dataIndex: 'name',
              align: 'left',
              width: '20%',
              title: '名称',
              customRender: function (text, records) {
                return (
                  <div class="calendar_name">
                    {text}
                    {text}
                  </div>
                )
              },
            },
            {
              dataIndex: 'originalPrice',
              align: 'left',
              width: '20%',
              title: '原价',
              customRender: function (text, records) {
                const onInput = (data) => {
                  if (data.target.value) {
                    if (!/[0-9\.]/.test(data.target.value)) {
                      data.target.value = null
                      that.$message.warning('请输入数字！')
                      return false
                    } else if (data.target.value < 0) {
                      that.$message.warning('请输入正数！')
                      data.target.value = null
                      return false
                    } else {
                      records.originalPrice = data.target.value
                    }
                  } else {
                    records.originalPrice = ''
                  }
                }
                return (
                  <a-input
                    type="number"
                    placeholder="请输入原价"
                    addon-before="￥"
                    onChange={onInput}
                    value={text}
                    min={0}
                  />
                )
              },
            },
            {
              dataIndex: 'salePrice',
              align: 'left',
              width: '20%',
              title: '销售价',
              customRender: function (text, records) {
                const onInput = (data) => {
                  if (data.target.value) {
                    if (!/[0-9\.]/.test(data.target.value)) {
                      data.target.value = null
                      that.$message.warning('请输入数字！')
                      return false
                    } else if (data.target.value < 0) {
                      that.$message.warning('请输入正数！')
                      data.target.value = null
                      return false
                    } else {
                      records.salePrice = data.target.value
                    }
                  } else {
                    records.salePrice = ''
                  }
                }
                return (
                  <a-input type="number" placeholder="请输入销售价" addon-before="￥" onInput={onInput} value={text} />
                )
              },
            },
            {
              dataIndex: 'jsPrice',
              align: 'left',
              width: '20%',
              title: '结算价',
              customRender: function (text, records) {
                const onInput = (data) => {
                  if (data.target.value) {
                    if (!/[0-9\.]/.test(data.target.value)) {
                      data.target.value = null
                      that.$message.warning('请输入数字！')
                      return false
                    } else if (data.target.value < 0) {
                      that.$message.warning('请输入正数！')
                      data.target.value = null
                      return false
                    } else {
                      records.jsPrice = data.target.value
                    }
                  } else {
                    records.jsPrice = ''
                  }
                }
                return (
                  <a-input type="number" placeholder="请输入结算价" addon-before="￥" onChange={onInput} value={text} />
                )
              },
            },
            {
              dataIndex: 'allStock',
              align: 'left',
              width: '20%',
              title: '库存',
              customRender: function (text, records) {
                const onInput = (data) => {
                  if (data.target.value) {
                    if (!/^\+?[1-9]\d*$/.test(data.target.value)) {
                      data.target.value = null
                      that.$message.warning('请输入正整数！')
                      return false
                    }
                    //  else if (data.target.value < 0) {
                    //   that.$message.warning('请输入正数！')
                    //   data.target.value = null
                    //   return false
                    // }
                    else {
                      records.allStock = data.target.value
                    }
                  } else {
                    records.allStock = ''
                  }
                }
                return <a-input type="number" placeholder="请输入库存" onChange={onInput} value={text} />
              },
            },
          ],
          dataSource: formInfo.batchSetData,
        },
      ]
    },
    batchSet() {
      if (this.isFree) {
        this.$message.error('免费模式下不能设置日历价')
        return
      }
      let dataArr = this.$refs.tableExternal.$refs.table.selectedRows.map((e) => e.skuId)
      if (dataArr.length == 0) {
        this.$message.error('至少勾选一个商品')
      } else {
        let batchSetData = this.records
          .filter((f) => dataArr.includes(f.skuId))
          .map((e) => ({
            skuId: e.skuId,
            name: e.productName,
            originalPrice: '',
            salePrice: '',
            jsPrice: '',
            allStock: '',
          }))

        let formData = {
          startDate: '',
          endDate: '',
          dateType: '',
          dateValue: '',
          batchSetData: batchSetData,
        }
        let flag = true
        apiTool.showModal({
          width: '800px',
          title: '日历价格设置',
          formData,
          form: () => this.getForm(formData),
          success: ({ data, setHidden }) => {
            const { productId } = this.$route.query
            let params = {
              ...data,
              skuPriceList: batchSetData,
              dateValue: data.dateValue.toString(),
              productId: productId,
              productType: this.productType,
            }
            let state = batchSetData.every((e) => e.originalPrice && e.allStock && e.salePrice)
            if (!state) {
              this.$message.error('请检查输入的价格')
              flag = true
              return
            }
            state = batchSetData.every((e) => parseFloat(e.jsPrice) <= parseFloat(e.salePrice))
            if (!state) {
              this.$message.error('结算价不能大于销售价')
              flag = true
              return
            }
            if (!flag) return
            flag = false
            this.$delete(params, 'batchSetData')
            const loadingNotice = this.$message.loading('提交中,请稍等', 0)
            console.log(params, '批量设置')
            postAction('/goods/farmTicketPriceConfig/saveBatch', params)
              .then((res) => {
                flag = true
                loadingNotice()
                if (res.code == 200) {
                  this.getInit()
                  this.$message.success('操作成功')
                  this.$refs.tableExternal.$refs.table.selectedRows = []
                  this.$refs.tableExternal.$refs.table.selectedRowKeys = []
                  setHidden()
                } else {
                  this.$message.error('操作失败')
                }
              })
              .catch((err) => {
                loadingNotice()

                flag = true
              })
          },
        })
      }
    },
    onDistribution() {
      const { productId, productType, thirdTicket, skuId } = this.$route.query
      let formData = {
        joinFx: 0,
        yjData: [
          {
            name: '订单佣金',
            value: 0,
          },
          {
            name: '下级佣金',
            value: 0,
          },
        ],
      }
      getAction('/goods/farmTicketPriceConfig/getDistribution', { productId, productType, skuId }).then((res) => {
        const data = res.data
        if (data) {
          formData = {
            joinFx: data.joinFx,
            yjData: [
              {
                name: '订单佣金',
                value: data.orderRate,
              },
              {
                name: '下级佣金',
                value: data.extendRate,
              },
            ],
          }
        }

        apiTool.showModal({
          width: '600px',
          title: '分销设置',
          formData: formData,
          form: () => this.getFxForm(formData),
          success: ({ data, setHidden }) => {
            let params = {
              productId,
              productType,
              skuId,
              joinFx: data.joinFx,
              orderRate: data.yjData[0].value,
              extendRate: data.yjData[1].value,
            }
            putAction('/goods/farmTicketPriceConfig/saveDistribution', params).then((res) => {
              this.$message.success('分销设置成功')
              setHidden()
            })
          },
        })
      })
    },
  },
  render() {
    return (
      <div class="body-card">
        {this.getHeaderRender()}
        <TemplateHeaderTable
          ref="tableExternal"
          disabled={this.isFree}
          tableColumns={this.getColumns()}
          records={this.records}
          // filterRightButtonKey={[]}
          showPagination={true}
          bordered={true}
        />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
/deep/.ant-card-body {
  padding-top: 4px;
}
.body-card {
  border: 1px solid rgba(232, 234, 236, 1);
  border-radius: 4px;
  min-height: calc(100vh - 128px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
}
/deep/ .template-card {
  border: 0;
  min-height: calc(100vh - 200px);
}
/deep/.ant-btn {
  border-radius: 5px;
}
.calendar_name {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  padding: 24px 24px 0 24px;
  i {
    cursor: pointer !important;
  }
  span {
    display: flex;
    align-items: center;
  }
}
.product-order-item-copy {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  // height: 55px;

  &.yes {
    cursor: pointer;

    &:hover {
      background-color: #e5e5e5;
    }
  }

  &.no {
    cursor: not-allowed;
  }
}
/deep/
  .ant-table-middle
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  padding: 0;
}
</style>
